<template>
    <div class="container">
        <Title titulo="Blocos de Tarefas" />

        <div class="navigate">
            <div class="navigate__filters">
                <div class="search-date">
                    <label for="date">Pesquisar por Data</label>
                    <input type="date" id="date" @input="searchDate($event)" />
                </div>

                <SearchInput texto="Pesquisar por loja" @changeInput="searchText" />
            </div>

            <Button texto="Salvar Alterações" @click="confirmaAcao" />
        </div>

        <Spinner v-if="isLoading" />

        <div v-else>
            <table v-if="blocosFiltrados.length > 0" class="tabela_blocos">
                <thead>
                    <tr>
                        <th>ENCERRAR</th>
                        <th>
                            <button @click="ordernaPorBloco">ID BLOCO</button><img :src="imagePath" alt="arrow order" />
                        </th>
                        <th>LOJA</th>
                        <th>DATA</th>
                        <th>DESCRIÇÃO</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(bloco, key) in blocosFiltrados" :key="key">
                        <td><input type="checkbox" name="encerrarBloco" :id="key" v-model="bloco.encerrar" /></td>
                        <td>{{ bloco.bloco }}</td>
                        <td>{{ bloco.loja }}</td>
                        <td>{{ bloco.data_bloco }}</td>
                        <td><input type="text" name="" id="" v-model="bloco.descricao" /></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Modal v-if="showModal" :isError="isError" :mensagem="modalMessage" @closeModal="showModal = false" />
    </div>
</template>

<script>
import Title from '../components/UI/Title.vue';
import Modal from '../components/Modal.vue';
import Spinner from '../components/UI/Spinner.vue';
import SearchInput from '../components/SearchInput.vue';
import Button from '../components/UI/Button.vue';

export default {
    components: {
        Title,
        Button,
        Modal,
        Spinner,
        SearchInput,
    },

    data() {
        return {
            isCreating: false,
            blocos: [],
            blocosFiltrados: [],
            showModal: false,
            isError: false,
            modalMessage: '',
            user: null,
            isLoading: true,
            headers: null,
            filtro_data: '',
            filtro_loja: '',
            bloco: '',
            ordemBloco: 'asc',
            ordemData: 'asc',
            image: 'arrow_drop_up.svg',
        };
    },

    computed: {
        imagePath() {
            return require(`../assets/images/${this.image}`);
        },
    },

    created() {
        this.user = JSON.parse(sessionStorage.getItem('user'));
        this.headers = { Accept: 'application/json', authorization: 'Bearer ' + this.user.token };

        this.axios
            .get(`${this.user.cliente}/blocos`, { headers: this.headers })
            .then((res) => {
                this.blocos = res.data;
                this.blocosFiltrados = res.data;
            })
            .catch(() => {
                this.isError = true;
                this.showModal = true;
                this.modalMessage =
                    'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    beforeRouteEnter(to, from, next) {
        const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));

        if (permissoes.permissoes.tela_tarefas == 0) {
            next({ path: '/usuarios' });
        } else {
            next();
        }
    },

    methods: {
        ordernaPorBloco() {
            if (this.ordemBloco == 'asc') {
                this.blocosFiltrados.sort((a, b) => (a.bloco > b.bloco ? 1 : b.bloco > a.bloco ? -1 : 0));

                this.image = 'arrow_drop_down.svg';

                this.ordemBloco = 'desc';
            } else if (this.ordemBloco == 'desc') {
                this.blocosFiltrados.sort((a, b) => (a.bloco < b.bloco ? 1 : b.bloco < a.bloco ? -1 : 0));
                this.image = 'arrow_drop_up.svg';

                this.ordemBloco = 'asc';
            }
        },

        ordernaPorData() {
            if (this.ordemBloco == 'asc') {
                this.blocosFiltrados.sort((a, b) =>
                    new Date(a.bloco) < new Date(b.bloco) ? 1 : new Date(b.bloco) < new Date(a.bloco) ? -1 : 0
                );

                this.ordemBloco = 'desc';
            } else if (this.ordemBloco == 'desc') {
                this.blocosFiltrados.sort((a, b) =>
                    new Date(a.bloco) > new Date(b.bloco) ? 1 : new Date(b.bloco) > new Date(a.bloco) ? -1 : 0
                );

                this.ordemBloco = 'asc';
            }
        },

        confirmaAcao() {
            const filtrado = this.blocosFiltrados.filter((element) => {
                return element.encerrar == true || element.descricao != null;
            });

            const data = { blocos: filtrado };

            this.axios
                .post(`${this.user.cliente}/blocos`, data, { headers: this.headers })
                .then(() => {
                    data.blocos.forEach((element) => {
                        if (element.encerrar == true) {
                            this.blocos = this.blocos.filter((produto) => {
                                return produto.bloco !== element.bloco;
                            });
                        }
                    });

                    this.blocos.sort((a, b) =>
                        new Date(a.bloco) < new Date(b.bloco) ? 1 : new Date(b.bloco) < new Date(a.bloco) ? -1 : 0
                    );

                    this.blocosFiltrados = this.blocos;

                    this.isError = false;
                    this.modalMessage = 'Dados atualizados com sucesso!';
                })
                .catch(() => {
                    this.isError = true;
                    this.modalMessage =
                        'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                })
                .finally(() => {
                    this.isCreating = false;
                    this.showModal = true;
                });
        },

        fechaOverlay() {
            this.isCreating = false;
            this.showModal = false;
        },

        searchText(text) {
            this.filtro_loja = text;
            this.filtraTarefas();
        },

        filtraTarefas() {
            if (this.filtro_data.trim().length > 0 && this.filtro_loja.trim().length > 0) {
                this.blocosFiltrados = this.blocos.filter((bloco) => {
                    return (
                        bloco.loja.toString().includes(this.filtro_loja) &&
                        bloco.data_bloco.toString().includes(this.filtro_data)
                    );
                });
            } else if (this.filtro_data.trim().length > 0 && this.filtro_loja.trim().length <= 0) {
                this.blocosFiltrados = this.blocos.filter((bloco) => {
                    return bloco.data_bloco.toString().includes(this.filtro_data);
                });
            } else if (this.filtro_loja.trim().length > 0 && this.filtro_data.trim().length <= 0) {
                this.blocosFiltrados = this.blocos.filter((bloco) => {
                    return bloco.loja.toString().includes(this.filtro_loja);
                });
            } else {
                this.blocosFiltrados = this.blocos;
            }
        },

        searchDate(event) {
            let date = event.target.value;
            date = new Date(`${date}T00:00`).toLocaleDateString('pt-BR');

            this.filtro_data = date;

            if (event.target.value == '') this.filtro_data = '';

            this.filtraTarefas();
        },
    },
};
</script>

<style lang="scss" scoped>
.navigate {
    display: grid;
    gap: 30px;
    margin-bottom: 50px;

    @media (min-width: 992px) {
        align-items: self-end;
        grid-template-columns: 1fr 250px;
        justify-content: space-between;
    }

    &__filters {
        display: grid;
        gap: 30px;
        grid-template-columns: 200px 450px;
        align-items: self-end;
    }
}

.search-date {
    & input {
        width: 100%;
        padding: 10px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
        color: #363636;
        border-radius: 4px;
        border: none;
        box-shadow: 0 0 3px #696969;
        font-size: 1.1rem;
    }

    & label {
        margin-bottom: 10px;
        color: #363636;
        display: block;
        font-weight: bold;
        letter-spacing: 0.4px;
    }
}

.modal-confirma {
    width: 240px;
    display: grid;
    gap: 25px;
    text-align: center;

    h4 {
        font-size: 1.8rem;
        color: #454545;

        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }

    p {
        color: #565656;
        letter-spacing: 0.5px;
        font-size: 1.1rem;
        display: flex;
        gap: 15px;
        justify-content: center;
    }

    img {
        width: 150px;
        justify-self: center;
        border: 2px solid;
        border-radius: 50%;

        &.error {
            border-color: #f72a2a;
        }

        &.success {
            border-color: #1a961a;
        }
    }

    &__buttons {
        display: grid;
        gap: 15px;

        @media (min-width: 768px) {
            grid-template-columns: 200px 200px;
            justify-content: space-between;
        }
    }

    button {
        border: none;
        background-color: transparent;
        font-size: 1.2rem;
        cursor: pointer;
        padding: 10px 0;
        border-radius: 10px;
        transition: 0.2s ease-in-out;
        color: #fff;

        &.error {
            background-color: #f72a2a;

            &:hover {
                background-color: darken($color: #f72a2a, $amount: 25);
            }
        }

        &.success {
            background-color: #1a961a;

            &:hover {
                background-color: darken($color: #1a961a, $amount: 25);
            }
        }
    }

    @media (min-width: 768px) {
        width: 450px;
    }
}

.tabela_blocos {
    width: 100%;

    thead tr {
        background-color: #363636;
        color: #fff;

        th {
            padding: 10px;
            text-align: left;
            letter-spacing: 0.5px;
            position: relative;
        }

        th:first-child {
            text-align: center;
            width: 140px;
        }

        th:nth-child(2) {
            padding: 0;
            width: 200px;
        }

        th:nth-child(3),
        th:nth-child(4) {
            width: 140px;
        }

        button {
            all: unset;
            cursor: pointer;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            right: 0;
            z-index: 50;
        }

        img {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 0;
        }
    }

    tbody tr {
        transition: 0.1s ease-in-out;

        &:hover {
            background-color: #b9b9b9;
        }

        td {
            padding: 10px;
            text-align: left;
        }

        td:first-child {
            text-align: center;
        }

        input[type='checkbox'] {
            text-align: center;
            transform: scale(1.6);
        }

        input[type='text'] {
            padding: 6px 10px;
            height: 30px;
            min-width: 500px;
            max-width: 800px;
            border: none;
            box-shadow: 0 0 5px #707070;
            border-radius: 2px;
        }
    }
}
</style>
